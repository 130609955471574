import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { format } from 'date-fns';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SourceList = makeShortcode("SourceList");
const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const Card = makeShortcode("Card");
const GatsbyImage = makeShortcode("GatsbyImage");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1><a parentName="h1" {...{
        "href": "#projects"
      }}>{`Projects`}</a></h1>
    <p>{`Here are all my notable Projects (or at least the one's I've made posts for):`}</p>
    <SourceList filter="projects" mdxType="SourceList">
  {projects => <Grid sx={{
        gridTemplateColumns: ['1fr', '1fr', '1fr 1fr']
      }} mdxType="Grid">
      {projects.filter(edge => !edge.node.frontmatter.isPrivate).reduce((routes, route) => {
          return route.node.frontmatter.pinned ? [route, ...routes] : [...routes, route];
        }, []).map((edge, index) => {
          const {
            featuredImageUrl,
            frontmatter: {
              title,
              featuredImage,
              tags,
              date,
              pinned
            },
            excerpt,
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column'
          }} mdxType="Box">
              <Link href={slug} sx={{
              textDecoration: 'none',
              display: 'flex',
              flex: '1 1 auto',
              flexDirection: 'column',
              minHeight: '1px'
            }} mdxType="Link">
                <Card sx={{
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                minHeight: '1px'
              }} mdxType="Card">
                  <Box sx={{
                  minHeight: '1px'
                }} mdxType="Box">
                    {featuredImage ? <GatsbyImage alt={title} image={featuredImage.childImageSharp} mdxType="GatsbyImage" /> : null}
                    {featuredImageUrl ? <GatsbyImage alt={title} image={featuredImageUrl.url.childImageSharp} mdxType="GatsbyImage" /> : null}
                  </Box>
                  <Box sx={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'column',
                  p: 3
                }} mdxType="Box">
                    <Heading variant="styles.h4" sx={{
                    color: pinned ? 'primary' : 'text'
                  }} mdxType="Heading">
                      {title}
                    </Heading>
                    <Text sx={{
                    mb: 1,
                    color: 'muted'
                  }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                    <Text sx={{
                    mb: 1,
                    color: 'text'
                  }} mdxType="Text">{excerpt}</Text>
                  </Box>
                  <Box sx={{
                  p: 3
                }} mdxType="Box">
                    <Text mdxType="Text">View Post</Text>
                  </Box>
                </Card>
              </Link>
            </Box>;
        })}
    </Grid>}
    </SourceList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      